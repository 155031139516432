import axios from 'axios';
import { useEffect, useState } from 'react';

const API_KEY = '3292a885edd71997bc9ff047a9bd091d';

// Ciudades con coordenadas para la API de clima
const ciudades = {
  'La Paz': 'La+Paz,mx',
  'Topolobampo': 'Topolobampo,mx',
  'Mazatlán': 'Mazatlán,mx',
};

const Clima = () => {
  const [climaCiudad, setClimaCiudad] = useState({});

  useEffect(() => {
    const fetchClimaCiudad = async () => {
      try {
        const climaPromises = Object.entries(ciudades).map(([ciudad, query]) =>
          axios.get(
            `https://api.openweathermap.org/data/2.5/forecast?q=${query}&appid=${API_KEY}&units=metric&lang=es`
          )
        );

        const respuestas = await Promise.all(climaPromises);
        const climaData = respuestas.reduce((acc, respuesta, index) => {
          const ciudad = Object.keys(ciudades)[index];
          const pronosticoDiario = {};

          // Agrupar los datos por fecha
          respuesta.data.list.forEach(item => {
            const fecha = item.dt_txt.split(' ')[0]; // Obtener solo la fecha (YYYY-MM-DD)
            if (!pronosticoDiario[fecha]) {
              pronosticoDiario[fecha] = {
                temperaturaMinima: item.main.temp_min,
                temperaturaMaxima: item.main.temp_max,
                descripcion: item.weather[0].description,
              };
            } else {
              // Actualizar la temperatura mínima y máxima si es necesario
              pronosticoDiario[fecha].temperaturaMinima = Math.min(pronosticoDiario[fecha].temperaturaMinima, item.main.temp_min);
              pronosticoDiario[fecha].temperaturaMaxima = Math.max(pronosticoDiario[fecha].temperaturaMaxima, item.main.temp_max);
            }
          });

          // Obtener solo los primeros 5 días
          acc[ciudad] = Object.entries(pronosticoDiario).slice(0, 5).map(([fecha, datos]) => ({
            fecha,
            temperaturaMinima: datos.temperaturaMinima,
            temperaturaMaxima: datos.temperaturaMaxima,
            descripcion: datos.descripcion,
          }));

          return acc;
        }, {});

        setClimaCiudad(climaData);
      } catch (err) {
        console.error('Error al obtener el clima de las ciudades:', err);
      }
    };

    fetchClimaCiudad();
  }, [API_KEY]);

  return (
    <div>
      {Object.entries(climaCiudad).map(([ciudad, pronostico]) => (
        <div key={ciudad}>
          <h2>{ciudad}</h2>
          <ul>
            {pronostico.map((dia, index) => (
              <li key={index}>
                {dia.fecha}: Mínima {dia.temperaturaMinima}°C, Máxima {dia.temperaturaMaxima}°C, {dia.descripcion}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default Clima;