import React, {useEffect, useState} from 'react'
import '../css/noticias.css'
import { Outlet, Link } from "react-router-dom";
import NewsletterBox from '../components/NewsletterBox';
import Footer from '../components/Footer'
export default function Noticias() {
    const [noticias, setNoticias] = useState([]);
  
    const loadTasks = async () => {

        //const response = await fetch('http://localhost:3004/noticias');
        //console.log('http://localhost:3004/noticias');
            
        const response = await fetch('https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws/noticias');
        const data = await response.json();
        console.log(data);
        setNoticias(data);
    }

    useEffect(() =>{
        loadTasks();
    },[])

    // Función para formatear la fecha
    const formatFecha = (fecha) => {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        return new Date(fecha).toLocaleDateString('es-ES', options);
    };

  return (
    <>
    <section>
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>Noticias y Avisos</h3>
                    <p>Descubre cómo Baja Ferries está marcando la diferencia.</p>
                </div>                
            </div>
        </div>
    </section>
      
    <section className='container'>
        <div className='noticias_content_area'>
            <div className='noticias_content'>
                {
                <div className='noticias_boxes'>
                    {noticias
                    .filter(noticia => noticia.visible) // Filtrar solo las tareas visibles
                    .sort((a, b) => new Date(b.creation_date) - new Date(a.creation_date)) // Ordenar por fecha descendente
                    .map((noticia) => (
                        <Link to={`/noticia/${noticia.id}`} key={noticia.id} className='noticia_box'>                                                                                        
                            <div className='noticia_img_header'>
                                <img src={'./images/noticias/'+noticia.photo_route_header} alt='noticia' />
                                
                            </div>   
                            {/*<div className='pruebaFly' style={{backgroundImage:`url('./images/noticias/${noticia.photo_route_header}')`}}>

                            </div>
                            css:
                            width: 100%;
                            height: 160px;
                            background-image: url(./images/noticias/1707321989123.png);
                            background-size: 100%;
                            background-repeat: no-repeat;
                            background-position: top;
                            */}
                            <div className='box_noticias_text'>
                                <div className='noticia_categoria'><i class="fi fi-br-bookmark"></i>{noticia.category}</div>
                                <div className='noticia_title' style={{ WebkitLineClamp: 3, display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical' }}>{noticia.title}</div>  
                                <div className='noticia_fecha'>{formatFecha(noticia.creation_date)}</div> 
                            </div>                                                                    
                        </Link> 
                    ))}
                </div>
                }                
            </div>
            {/*<div className='noticias_content_newsletter'>
                <div className='noticias_box_newsletter'>
                    <h2>Suscríbete a nuestro newsetter</h2>
                    <p>Ingresa tu correo electrónico para recibir noticias, avisos y promociones especiales. </p>
                    <div className='noticias_input_newsletter'>
                        <input type='mail' name='newsletter' placeholder='Ingresa tu correo'/>
                        <button>suscribirme </button>
                    </div>
                </div>

            </div>*/}
            <NewsletterBox />
           

        </div>

    </section>
 
    <Outlet />
    <Footer />

    </>

  )
}


