import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import axios from 'axios';
import '../css/viaje_curso.css'
export default function OceanTraking() {
  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const [fecha, setFecha] = useState(new Date());
  const [data, setData] = useState([]); // Itinerario programado
  const [enCurso, setEnCurso] = useState([]); // Viajes en curso
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const intervalo = setInterval(() => {
      setFecha(new Date());
    }, 1000);
    return () => clearInterval(intervalo);
  }, []);

  const fechaFormateada = capitalize(
    format(fecha, "EEEE dd 'de' MMMM yyyy", { locale: es })
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const itinerarioResponse = await axios.get(
          'https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws/getTrackingEmbarque'
        );

        const enCursoResponse = await axios.get(
          'https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws/getViajeCurso'
        );

        const enCursoProcesado = enCursoResponse.data.map((item) => {
          const fechaRegistro = new Date();
          const diaSemana = format(fechaRegistro, 'EEEE', { locale: es });
          return { ...item, dia: capitalize(diaSemana), estatus: 'En curso' };
        });

        setData(itinerarioResponse.data);
        setEnCurso(enCursoProcesado);
        setLoading(false);
      } catch (error) {
        console.error('Error al obtener los datos:', error);
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const getOrderedDays = () => {
    const daysOfWeek = [
      'domingo',
      'lunes',
      'martes',
      'miércoles',
      'jueves',
      'viernes',
      'sábado',
    ];
    const todayIndex = fecha.getDay();
    return daysOfWeek
      .slice(todayIndex)
      .concat(daysOfWeek.slice(0, todayIndex))
      .map((day) => capitalize(day));
  };

  const combinarViajes = () => {
    const groupedData = getOrderedDays().reduce((acc, day) => {
      acc[day] = [];
      return acc;
    }, {});

    data.forEach((viaje) => {
      const diaFormatted = capitalize(viaje.dia);
      if (groupedData[diaFormatted]) {
        groupedData[diaFormatted].push(viaje);
      }
    });

    enCurso.forEach((viajeEnCurso) => {
      const diaFormatted = capitalize(viajeEnCurso.dia);
      if (groupedData[diaFormatted]) {
        const index = groupedData[diaFormatted].findIndex(
          (v) =>
            v.ruta === viajeEnCurso.ruta &&
            v.buque === viajeEnCurso.buque &&
            v.salida_hora === viajeEnCurso.salida_hora
        );
        if (index !== -1) {
          groupedData[diaFormatted][index] = viajeEnCurso;
        } else {
          groupedData[diaFormatted].push(viajeEnCurso);
        }
      }
    });

    return groupedData;
  };

  if (loading) return <p>Cargando datos...</p>;
  if (error) return <p>Error al cargar datos: {error.message}</p>;

  const groupedData = combinarViajes();
  const orderedDays = getOrderedDays();
  const today = orderedDays[0]; // El día actual

  return (
    <>
      <section>
        <div className="header_section">
          <div className="container header_section_box">
            <div className="header_section_title">
              <h3>Itinerario</h3>
              <p>Conoce la hora estimada de llegada para tu viaje</p>
            </div>
          </div>
        </div>
      </section>

      <section className="panel_viaje_curso">
        <div className="container">
          <div className="content-viaje-curso">
            {/* Tabla del día actual */}
            <div className="daily-table">
                <div className="barra1_viaje_curso">
                    <label>{fechaFormateada}</label>
                    <label> Viaje en curso</label>                
                </div>
              <table style={{ width: '100%', textAlign: 'left' }} className="viaje-curso-tabla">
                <thead>
                  <tr>
                    <th>Ruta</th>
                    <th>Buque</th>
                    <th>Salida</th>
                    <th>Llegada</th>
                    <th>Muelle</th>
                    <th>Estatus</th>
                  </tr>
                </thead>
                <tbody>
                  {groupedData[today] && groupedData[today].length > 0 ? (
                    groupedData[today].map((row) => (
                      <tr key={`${row.ruta}-${row.salida_hora}`}>
                        <td>{row.ruta}</td>
                        <td>{row.buque}</td>
                        <td>{row.salida_hora} HRS.</td>
                        <td>{row.llegada_hora} HRS.</td>
                        <td>{row.muelle}</td>
                        <td
                          style={{
                            fontWeight: '600',
                            color:
                              row.estatus === 'En curso'
                                ? '#28A744'
                                : '#007BFF',
                          }}
                        >
                          {row.estatus}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" style={{ textAlign: 'center' }}>
                        No hay datos disponibles
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            
          </div>
          <div className="content-viaje-curso">

            {/* Tabla de los siguientes días */}
            <div className="daily-table">
            <div className="barra1_viaje_curso">
              <label></label>
               {/*<label> Travesía en tiempo real</label>*/}
               <label>Próximos viajes</label>
            </div>
              {orderedDays.slice(1).map((day) => (
                <div key={day}>
                  <h5>{day}</h5>
                  <table style={{ width: '100%', textAlign: 'left', marginBottom: '20px' }}  className="viaje-curso-tabla" >
                    <thead>
                      <tr>
                        <th>Ruta</th>
                        <th>Buque</th>
                        <th>Salida</th>
                        <th>Llegada</th>
                        <th>Muelle</th>
                        <th>Estatus</th>
                      </tr>
                    </thead>
                    <tbody>
                      {groupedData[day] && groupedData[day].length > 0 ? (
                        groupedData[day].map((row) => (
                          <tr key={`${row.ruta}-${row.salida_hora}`}>
                            <td>{row.ruta}</td>
                            <td>{row.buque}</td>
                            <td>{row.salida_hora} HRS.</td>
                            <td>{row.llegada_hora} HRS.</td>
                            <td>{row.muelle}</td>
                            <td
                              style={{
                                fontWeight: '600',
                                color:
                                  row.estatus === 'En curso'
                                    ? '#28A744'
                                    : '#007BFF',
                              }}
                            >
                              {row.estatus}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6" style={{ textAlign: 'center' }}>
                            No hay datos disponibles
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
