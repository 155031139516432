import React, { useState, useEffect } from 'react';
import { parseISO, format } from 'date-fns';
import { es } from 'date-fns/locale';
import axios from 'axios';
import '../css/viaje_curso.css';
import { Outlet, Link } from "react-router-dom";
import reservacion from '../assets/images/cta/reservacion.svg'
import tarifas from '../assets/images/cta/tarifas.svg'
import preguntas from '../assets/images/pregutas.png'
import Footer from '../components/Footer';

export default function ViajeCurso() {
  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
  const [mensajeAbierto, setMensajeAbierto] = useState(null);

  const [fecha, setFecha] = useState(new Date());
  const [data, setData] = useState([]); // Itinerario programado
  const [enCurso, setEnCurso] = useState([]); // Viajes en curso
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleButtonClick = (ruta) => {
    // Si ya hay un mensaje abierto para esa ruta, lo cerramos, si no, mostramos el nuevo mensaje.
    setMensajeAbierto(mensajeAbierto === ruta ? null : ruta);
  };

  //clima

  const [climaCiudades, setClimaCiudades] = useState({});
  const [climaCiudad, setClimaCiudad] = useState({});
  // Tu nueva clave API
  const API_KEY = '3292a885edd71997bc9ff047a9bd091d';

   // Ciudades con coordenadas para la API de clima
   const ciudades = {
    'La Paz': 'La+Paz,mx',
    'Topolobampo': 'Topolobampo,mx',
    'Mazatlán': 'Mazatlán,mx',
  };


    useEffect(() => {
    const fetchClimaCiudades = async () => {
      try {
        const climaPromises = Object.entries(ciudades).map(([ciudad, query]) =>
          axios.get(
            `https://api.openweathermap.org/data/2.5/weather?q=${query}&appid=${API_KEY}&units=metric`
          )
        );

        const responses = await Promise.all(climaPromises);
        const climaData = responses.reduce((acc, response, index) => {
          const ciudad = Object.keys(ciudades)[index];
          acc[ciudad] = response.data.main.temp;
          return acc;
        }, {});

        setClimaCiudades(climaData);
      } catch (err) {
        console.error('Error al obtener el clima de las ciudades:', err);
      }
    };

    fetchClimaCiudades();
  }, [API_KEY]);

  useEffect(() => {
    const fetchClimaCiudad = async () => {
      try {
        const climaPromises = Object.entries(ciudades).map(([ciudad, query]) =>
          axios.get(
            `https://api.openweathermap.org/data/2.5/forecast?q=${query}&appid=${API_KEY}&units=metric&lang=es`
          )
        );

        const respuestas = await Promise.all(climaPromises);
        const climaData = respuestas.reduce((acc, respuesta, index) => {
          const ciudad = Object.keys(ciudades)[index];
          const pronosticoDiario = {};

          // Agrupar los datos por fecha
          respuesta.data.list.forEach(item => {
            const fecha = item.dt_txt.split(' ')[0]; // Obtener solo la fecha (YYYY-MM-DD)
            if (!pronosticoDiario[fecha]) {
              pronosticoDiario[fecha] = {
                temperaturaMinima: item.main.temp_min,
                temperaturaMaxima: item.main.temp_max,
                descripcion: item.weather[0].description,
              };
            } else {
              // Actualizar la temperatura mínima y máxima si es necesario
              pronosticoDiario[fecha].temperaturaMinima = Math.min(pronosticoDiario[fecha].temperaturaMinima, item.main.temp_min);
              pronosticoDiario[fecha].temperaturaMaxima = Math.max(pronosticoDiario[fecha].temperaturaMaxima, item.main.temp_max);
            }
          });

          // Obtener solo los primeros 5 días
          acc[ciudad] = Object.entries(pronosticoDiario).slice(0, 5).map(([fecha, datos]) => ({
            fecha,
            temperaturaMinima: datos.temperaturaMinima,
            temperaturaMaxima: datos.temperaturaMaxima,
            descripcion: datos.descripcion,
          }));

          return acc;
        }, {});

        setClimaCiudad(climaData);
      } catch (err) {
        console.error('Error al obtener el clima de las ciudades:', err);
      }
    };

    fetchClimaCiudad();
  }, [API_KEY]);
  //Fin clima



  useEffect(() => {
    const intervalo = setInterval(() => {
      setFecha(new Date());
    }, 1000);
    return () => clearInterval(intervalo);
  }, []);

  const fechaFormateada = capitalize(
    format(fecha, "EEEE dd 'de' MMMM yyyy", { locale: es })
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Obtener datos del itinerario programado
        const itinerarioResponse = await axios.get(
          'https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws/getTrackingEmbarque'
        );
  
        // Obtener datos de viajes en curso
        const enCursoResponse = await axios.get(
         // 'http://etaservice.bajaferries.net:9005/services/ETAService.asmx/Get_Recent_Trips'
          'https://services.bajaferries.net/services/EtaService.asmx/Get_Recent_Trips'
        );
  
        // Validar y procesar la respuesta de enCursoResponse
        const responseData = JSON.parse(enCursoResponse.data); // Primera capa de parseo
        console.log("ResponseData recibido:", responseData);
  
        const enCursoData =
          responseData?.data && JSON.parse(responseData.data) instanceof Array
            ? JSON.parse(responseData.data)
            : [];
  
        console.log("Datos procesados de enCurso:", enCursoData);
  
        // Procesar los datos de enCurso
        const enCursoProcesado = enCursoData
          .filter(esRegistroValido) // Filtrar solo registros válidos
          .map((trip, index) => {
            if (!trip || trip.length < 4) {
              console.warn(`Viaje inválido en posición ${index}:`, trip);
              return null; // Ignora viajes inválidos
            }
  
            const salida = trip[0]?.split(' ')[1] || 'Sin datos';
            const llegada = trip[2]?.split(' ')[1] || 'Sin datos';
            const rutaAbreviada = trip[1] || 'Ruta desconocida';
            const buque = trip[3] || 'Buque desconocido';
  
            const rutaCompleta = convertirNombreRuta(rutaAbreviada);
  
            const fechaRegistro = new Date();
            const diaSemana = format(fechaRegistro, 'EEEE', { locale: es });
  
            return {
              dia: capitalize(diaSemana),
              ruta: rutaCompleta,
              salida_hora: salida,
              llegada_hora: llegada,
              buque,
              estatus: 'En curso',
            };
          })
          .filter((viaje) => viaje !== null);
  
        console.log("Datos de enCurso procesados:", enCursoProcesado);
  
        // Establecer los datos en el estado
        setData(itinerarioResponse.data || []); // Itinerario programado
        setEnCurso(enCursoProcesado || []); // Viajes en curso
        setLoading(false);
      } catch (error) {
        console.error('Error al obtener los datos:', error);
        setError({
          message: error?.message || 'Error desconocido',
          details: error?.response?.data || null,
        });
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  
  
  const convertirNombreRuta = (ruta) => {
    switch (ruta) {
      case 'PIC-MZT':
        return 'La Paz - Mazatlán';
      case 'MZT-PIC':
        return 'Mazatlán - La Paz';
      case 'TPB-PIC':
        return 'Topolobampo - La Paz';
      case 'PIC-TPB':
        return 'La Paz - Topolobampo';
      default:
        return ruta; // Devuelve el nombre original si no coincide con los casos
    }
  };

  const esRegistroValido = (trip) => {
    return trip && trip.every((campo) => campo !== null);
  };
  
  const getOrderedDays = () => {
    const daysOfWeek = [
      'domingo',
      'lunes',
      'martes',
      'miércoles',
      'jueves',
      'viernes',
      'sábado',
    ];
    const todayIndex = fecha.getDay();
    return daysOfWeek
      .slice(todayIndex)
      .concat(daysOfWeek.slice(0, todayIndex))
      .map((day) => capitalize(day));
  };

  const normalizeTime = (time) => {
    if (!time) return ''; // Si no hay hora, devolvemos una cadena vacía
  
    // Eliminamos cualquier espacio o "HRS." adicional, si existe, antes de procesar la hora
    const timeWithoutHRS = time.replace(' HRS.', '').trim();
  
    // Si la hora tiene segundos, los eliminamos
    const timeWithoutSeconds = timeWithoutHRS.split(':').slice(0, 2).join(':'); // Solo HH:mm
  
    return `${timeWithoutSeconds} HRS.`; // Devuelve el formato final: "HH:mm HRS."
  };
  /*
  const combinarViajes = () => {
    const groupedData = getOrderedDays().reduce((acc, day) => {
      acc[day] = [];
      return acc;
    }, {});
  
    // Definir una función para determinar el muelle según la ruta
    const determinarMuelle = (ruta) => {
      switch (ruta) {
        case 'La Paz - Topolobampo':
        case 'Topolobampo - La Paz':
        case 'Mazatlán - La Paz':
          return 'Muelle 1';
        case 'La Paz - Mazatlán':
          return 'Muelle 2';
        default:
          return 'Muelle desconocido'; // Valor por defecto si la ruta no coincide
      }
    };
  
    // Agregar los itinerarios programados
    data.forEach((viaje) => {
      // Normaliza las horas antes de agregar
      viaje.salida_hora = normalizeTime(viaje.salida_hora);
      viaje.llegada_hora = normalizeTime(viaje.llegada_hora);
  
      // Asignar la propiedad muelle
      viaje.muelle = determinarMuelle(viaje.ruta);
  
      const diaFormatted = capitalize(viaje.dia);
      if (groupedData[diaFormatted]) {
        groupedData[diaFormatted].push(viaje);
      }
    });
  
    // Reemplazar o agregar los viajes en curso
    enCurso.forEach((viajeEnCurso) => {
      // Normaliza las horas antes de agregar
      viajeEnCurso.salida_hora = normalizeTime(viajeEnCurso.salida_hora);
      viajeEnCurso.llegada_hora = normalizeTime(viajeEnCurso.llegada_hora);
  
      // Asignar la propiedad muelle
      viajeEnCurso.muelle = determinarMuelle(viajeEnCurso.ruta);
  
      const diaFormatted = capitalize(viajeEnCurso.dia);
      if (groupedData[diaFormatted]) {
        const index = groupedData[diaFormatted].findIndex(
          (v) =>
            v.ruta === viajeEnCurso.ruta
        );
        if (index !== -1) {
          // Si existe el viaje programado, lo reemplazamos por el viaje en curso
          groupedData[diaFormatted][index] = viajeEnCurso;
        } else {
          // Si no existe el viaje programado, agregamos el viaje en curso
          groupedData[diaFormatted].push(viajeEnCurso);
        }
      }
    });
  
    return groupedData;
  };
  */
  const combinarViajes = () => {
    const groupedData = getOrderedDays().reduce((acc, day) => {
      acc[day] = [];
      return acc;
    }, {});
  
    // Definir una función para determinar el muelle según la ruta
    const determinarMuelle = (ruta) => {
      switch (ruta) {
        case 'La Paz - Topolobampo':          
        case 'Topolobampo - La Paz':
          return 'Muelle 1';
        case 'Mazatlán - La Paz':
          return 'Muelle 1 Bis';
        case 'La Paz - Mazatlán':
          return 'Muelle 2';
        default:
          return 'Muelle desconocido'; // Valor por defecto si la ruta no coincide
      }
    };
  
    // Agregar los itinerarios programados
    data.forEach((viaje) => {
      // Normaliza las horas antes de agregar
      viaje.salida_hora = normalizeTime(viaje.salida_hora);
      viaje.llegada_hora = normalizeTime(viaje.llegada_hora);
  
      // Asignar la propiedad muelle
      viaje.muelle = determinarMuelle(viaje.ruta);
  
      const diaFormatted = capitalize(viaje.dia);
      if (groupedData[diaFormatted]) {
        groupedData[diaFormatted].push(viaje);
      }
    });
  
    // Reemplazar o agregar los viajes en curso
    enCurso.forEach((viajeEnCurso) => {
      // Normaliza las horas antes de agregar
      viajeEnCurso.salida_hora = normalizeTime(viajeEnCurso.salida_hora);
      viajeEnCurso.llegada_hora = normalizeTime(viajeEnCurso.llegada_hora);
  
      // Asignar la propiedad muelle
      viajeEnCurso.muelle = determinarMuelle(viajeEnCurso.ruta);
  
      const diaFormatted = capitalize(viajeEnCurso.dia);
      if (groupedData[diaFormatted]) {
        const index = groupedData[diaFormatted].findIndex(
          (v) =>
            v.ruta === viajeEnCurso.ruta
        );
        if (index !== -1) {
          // Si existe el viaje programado, lo reemplazamos por el viaje en curso
          groupedData[diaFormatted][index] = viajeEnCurso;
        } else {
          // Si no existe el viaje programado, agregamos el viaje en curso
          groupedData[diaFormatted].push(viajeEnCurso);
        }
      }
    });
  
    // Ordenar los viajes para que los que están "En curso" aparezcan primero
    Object.keys(groupedData).forEach((day) => {
      groupedData[day].sort((a, b) => {
        if (a.estatus === 'En curso' && b.estatus !== 'En curso') return -1;
        if (a.estatus !== 'En curso' && b.estatus === 'En curso') return 1;
        return 0; // Mantener el orden original si ambos tienen el mismo estatus
      });
    });
  
    return groupedData;
  };
  
  {/* Objeto de mapeo para asociar descripciones con íconos */}
const weatherIcons = {
  "cielo claro": <i class="fi fi-rs-sun"></i>,
  "nubes dispersas": <i class="fi fi-rs-clouds"></i>,
  "nubes": <i class="fi fi-rr-clouds"></i>,
  "nublado": <i class="fi fi-rs-clouds-sun"></i>,
  "muy nuboso": <i class="fi fi-rr-smoke"></i>,
  "lluvia ligera": <i class="fi fi-rs-drizzle"></i>,
  "lluvia": <i class="fi fi-rs-rain"></i>,
  "tormenta": <i class="fi fi-rs-thunderstorm"></i>,
  "algo de nubes": <i class="fi fi-rr-cloud-sun"></i>,
  "neblina": <i class="fi fi-rs-fog"></i>,
 
};

  if (loading) return <p>Cargando datos...</p>;
  if (error) return <p>Error al cargar datos: {error.message}</p>;

  const groupedData = combinarViajes();
  const orderedDays = getOrderedDays();
  const today = orderedDays[0]; // El día actual

  return (
    <>
      <section>
        <div className="header_section">
          <div className="container header_section_box">
            <div className="header_section_title">
              <h3>Itinerario</h3>
              <p>Conoce la hora estimada de llegada para tu viaje</p>
            </div>
          </div>
        </div>
      </section>
      
      <section className='d-none d-sm-none d-md-block'>
        <div className="panel_viaje_curso">
          <div className="container">
            <div className="content-viaje-curso">
              {/* Tabla del día actual */}
              <div className="daily-table">
                <div className="barra1_viaje_curso">
                  <label>{fechaFormateada}</label>
                  <label> Viaje en curso</label>
                </div>
                <table
                style={{ width: '100%', textAlign: 'left' }}
                className="viaje-curso-tabla">
                <thead>
                  <tr>
                    <th>Ruta</th>
                    <th></th>
                    <th>Salida</th>
                    <th>Llegada</th>
                    <th>Buque</th>
                    <th>Muelle</th> {/* Nueva columna */}
                    <th>Estatus</th>
                  </tr>
                </thead>

                <tbody>
                {groupedData[today] && groupedData[today].length > 0 ? (
                  groupedData[today].map((row) => {
                    const destino = row.ruta.split(' - ')[1]; // Obtén el destino de la ruta
                    const climaDestino = climaCiudad[destino]?.[0]; // Usa el pronóstico del primer día para el destino
                    
                    return (
                      <React.Fragment key={`${row.ruta}-${row.salida_hora}`}>
                        <tr>
                          <td>{row.ruta}</td>
                          <td>
                            <button onClick={() => handleButtonClick(row.ruta)}>
                              <i className="fi fi-rs-temperature-high" style={{marginRight:'2px'}}></i> 
                              {climaDestino ? (
                                <>
                                  Mín {Math.round(climaDestino.temperaturaMinima)}°C / Máx {Math.round(climaDestino.temperaturaMaxima)}°C
                                </>
                              ) : (
                                'Cargando...'
                              )}
                            </button>
                          </td>
                          <td>{row.salida_hora}</td>
                          <td>{row.llegada_hora}</td>
                          {/*<td>{row.buque}</td>*/}
                          <td style={{textTransform: 'none'}}>{row.buque.charAt(0).toUpperCase() + row.buque.slice(1).toLowerCase()}</td>
                          <td>{row.muelle}</td> {/* Mostrar el muelle */}
                          <td
                            style={{
                              textTransform:'uppercase',
                              fontWeight: '600',
                              color: row.estatus === 'En curso' ? '#28A744' : '#007BFF',
                            }}>
                            {row.estatus}
                          </td>
                        </tr>
                        {/* Mostrar el mensaje debajo de la fila si esta ruta tiene el mensaje abierto */}
                        {mensajeAbierto === row.ruta && (
                        <tr>
                          <td colSpan="7" style={{ padding: '10px', textAlign: 'center' }}>
                            <div className="mensaje">
                              
                              {/* Extraer el destino de la ruta */}
                              {(() => {
                                const destino = row.ruta.split(' - ')[1]; // Obtener el destino
                                const climaDestino = climaCiudad[destino]; // Obtener el clima de la ciudad de destino
                      
                                if (climaDestino) {
                                  return (
                                    <div>
                                      <div className='clima_title'>
                                        <label>Clima</label>
                                        <h4> {destino}</h4>
                                      </div>
                                      <ul className='clima_box'>
                                        {climaDestino.map((dia, index) => (
                                        <li key={index}>
                                          
                                          {/*{dia.fecha}: Mín {dia.temperaturaMinima}°C, Máx {dia.temperaturaMaxima}°C, {dia.descripcion}*/}
                                          <div>{format(parseISO(dia.fecha), "EEEE d", { locale: es })}</div>  
                                          {/*<div>Mín {dia.temperaturaMinima}°C / Máx {dia.temperaturaMaxima}°C </div>*/}
                                          <div> Mín {Math.round(dia.temperaturaMinima)}°C / Máx {Math.round(dia.temperaturaMaxima)}°C</div>
                                          <div> {weatherIcons[dia.descripcion] || <i class="fi fi-rs-question"></i>} {/* Ícono por descripción */}{dia.descripcion}</div>                                      
                                        </li>

                                        ))}
                                      </ul>
                                    </div>
                                  );
                                } else {
                                  return <p>No hay datos de clima disponibles para {destino}.</p>;
                                }
                              })()}
                            </div>
                          </td>
                        </tr>
                                    )}
                      </React.Fragment>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="6" style={{ textAlign: 'center' }}>
                      No hay datos disponibles
                    </td>
                  </tr>
                )}
              </tbody>

            
              </table>
              </div>
            </div>
            <div className="content-viaje-curso">
              {/* Tabla de los siguientes días */}
              <div className="daily-table">
                <div className="barra1_viaje_curso">
                  <label></label>
                  {/*<label> Travesía en tiempo real</label>*/}
                  <label>Próximos viajes</label>
                </div>
                <table style={{ width: '100%', textAlign: 'left', marginBottom: '20px' }} className="viaje-curso-tabla">
                  <thead>
                    <tr>
                      <th>Ruta</th>  
                      <th></th>                    
                      <th>Salida</th>
                      <th>Llegada</th>
                      <th>Buque</th>
                      <th>Muelle</th>
                      <th>Estatus</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderedDays.slice(1).map((day) => (
                      <React.Fragment key={day}>
                        <tr>
                          <td colSpan="6" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '32px' }}>
                            {day}
                          </td>
                        </tr>
                        {groupedData[day] && groupedData[day].length > 0 ? (
                          groupedData[day].map((row) => (
                            <tr key={`${row.ruta}-${row.salida_hora}`}>
                              <td>{row.ruta}</td>   
                              <td></td>                         
                              <td>{row.salida_hora}</td>
                              <td>{row.llegada_hora}</td>
                              <td>{row.buque}</td>
                              <td>{row.muelle}</td>
                              <td
                                style={{
                                  fontWeight: '600',
                                  color: row.estatus === 'En curso' ? '#28A744' : '#007BFF',
                                }}
                              >
                                {row.estatus}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6" style={{ textAlign: 'center' }}>
                              No hay datos disponibles
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </section>
{/*
      <section className='d-block d-sm-block d-md-none'>
        <div className="panel_viaje_curso">
          <div className="container">
            <div className="content-viaje-curso">
              {/* Tabla del día actual /}
              <div className="daily-table">
                <div className="barra1_viaje_curso">
                  <label>{fechaFormateada}</label>
                  <label> Viaje en curso</label>
                </div>
                <table
                style={{ width: '100%', textAlign: 'left' }}
                className="viaje-curso-tabla">
                <thead>
                  <tr>
                    <th>Ruta</th>
                    <th></th>
                    <th>Salida</th>
                    <th>Llegada</th>
                    <th>Buque</th>
                    <th>Muelle</th> {/* Nueva columna /}
                    <th>Estatus</th>
                  </tr>
                </thead>

                <tbody>
                {groupedData[today] && groupedData[today].length > 0 ? (
                  groupedData[today].map((row) => {
                    const destino = row.ruta.split(' - ')[1]; // Obtén el destino de la ruta
                    const climaDestino = climaCiudad[destino]?.[0]; // Usa el pronóstico del primer día para el destino
                    
                    return (
                      <React.Fragment key={`${row.ruta}-${row.salida_hora}`}>
                        <tr>
                          <td>{row.ruta}</td>
                          <td>
                            <button onClick={() => handleButtonClick(row.ruta)}>
                              <i className="fi fi-rs-temperature-high" style={{marginRight:'2px'}}></i> 
                              {climaDestino ? (
                                <>
                                  Mín {Math.round(climaDestino.temperaturaMinima)}°C / Máx {Math.round(climaDestino.temperaturaMaxima)}°C
                                </>
                              ) : (
                                'Cargando...'
                              )}
                            </button>
                          </td>
                          <td>{row.salida_hora}</td>
                          <td>{row.llegada_hora}</td>
                          {/*<td>{row.buque}</td>/}
                          <td style={{textTransform: 'none'}}>{row.buque.charAt(0).toUpperCase() + row.buque.slice(1).toLowerCase()}</td>
                          <td>{row.muelle}</td> {/* Mostrar el muelle /}
                          <td
                            style={{
                              textTransform:'uppercase',
                              fontWeight: '600',
                              color: row.estatus === 'En curso' ? '#28A744' : '#007BFF',
                            }}>
                            {row.estatus}
                          </td>
                        </tr>
                        {/* Mostrar el mensaje debajo de la fila si esta ruta tiene el mensaje abierto /}
                        {mensajeAbierto === row.ruta && (
                        <tr>
                          <td colSpan="7" style={{ padding: '10px', textAlign: 'center' }}>
                            <div className="mensaje">
                              
                              {/* Extraer el destino de la ruta /}
                              {(() => {
                                const destino = row.ruta.split(' - ')[1]; // Obtener el destino
                                const climaDestino = climaCiudad[destino]; // Obtener el clima de la ciudad de destino
                      
                                if (climaDestino) {
                                  return (
                                    <div>
                                      <div className='clima_title'>
                                        <label>Clima</label>
                                        <h4> {destino}</h4>
                                      </div>
                                      <ul className='clima_box'>
                                        {climaDestino.map((dia, index) => (
                                        <li key={index}>
                                          
                                          {/*{dia.fecha}: Mín {dia.temperaturaMinima}°C, Máx {dia.temperaturaMaxima}°C, {dia.descripcion}/}
                                          <div>{format(parseISO(dia.fecha), "EEEE d", { locale: es })}</div>  
                                          {/*<div>Mín {dia.temperaturaMinima}°C / Máx {dia.temperaturaMaxima}°C </div>/}
                                          <div> Mín {Math.round(dia.temperaturaMinima)}°C / Máx {Math.round(dia.temperaturaMaxima)}°C</div>
                                          <div> {weatherIcons[dia.descripcion] || <i class="fi fi-rs-question"></i>} {/* Ícono por descripción /}{dia.descripcion}</div>                                      
                                        </li>

                                        ))}
                                      </ul>
                                    </div>
                                  );
                                } else {
                                  return <p>No hay datos de clima disponibles para {destino}.</p>;
                                }
                              })()}
                            </div>
                          </td>
                        </tr>
                                    )}
                      </React.Fragment>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="6" style={{ textAlign: 'center' }}>
                      No hay datos disponibles
                    </td>
                  </tr>
                )}
              </tbody>

            
              </table>
              </div>
            </div>
            <div className="content-viaje-curso">
              {/* Tabla de los siguientes días /}
              <div className="daily-table">
                <div className="barra1_viaje_curso">
                  <label></label>
                  {/*<label> Travesía en tiempo real</label>/}
                  <label>Próximos viajes</label>
                </div>
                <table style={{ width: '100%', textAlign: 'left', marginBottom: '20px' }} className="viaje-curso-tabla">
                  <thead>
                    <tr>
                      <th>Ruta</th>                    
                      <th>Salida</th>
                      <th>Llegada</th>
                      <th>Buque</th>
                      <th>Muelle</th>
                      <th>Estatus</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderedDays.slice(1).map((day) => (
                      <React.Fragment key={day}>
                        <tr>
                          <td colSpan="6" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '32px' }}>
                            {day}
                          </td>
                        </tr>
                        {groupedData[day] && groupedData[day].length > 0 ? (
                          groupedData[day].map((row) => (
                            <tr key={`${row.ruta}-${row.salida_hora}`}>
                              <td>{row.ruta}</td>                            
                              <td>{row.salida_hora}</td>
                              <td>{row.llegada_hora}</td>
                              <td>{row.buque}</td>
                              <td>{row.muelle}</td>
                              <td
                                style={{
                                  fontWeight: '600',
                                  color: row.estatus === 'En curso' ? '#28A744' : '#007BFF',
                                }}
                              >
                                {row.estatus}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6" style={{ textAlign: 'center' }}>
                              No hay datos disponibles
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </section>

    */}
      
      <section className='d-block d-sm-block d-md-none'>
          <div className="panel_viaje_curso">
            <div className="container">
              <div className="content-viaje-curso">
                <div className="daily-table">
                  <div className="barra1_viaje_curso">
                    <label>{fechaFormateada}</label>
                    <label>Viaje en curso</label>
                  </div>

                  {/* Agregar table-responsive */}
                  <div className="table-responsive">
                    <table className="table viaje-curso-tabla">                      
                      <tbody>
                        {groupedData[today] && groupedData[today].length > 0 ? (
                          groupedData[today].map((row) => {
                            const destino = row.ruta.split(' - ')[1];
                            const climaDestino = climaCiudad[destino]?.[0];

                            return (
                              <React.Fragment key={`${row.ruta}-${row.salida_hora}`}>
                                {/* Estructura vertical en móviles */}
                                <tr className="d-flex flex-column flex-md-row">
                                  <td><strong>Ruta:</strong> {row.ruta}</td>
                                  <td>
                                    <strong>Clima:</strong> 
                                    <button onClick={() => handleButtonClick(row.ruta)}>
                                      <i className="fi fi-rs-temperature-high" style={{ marginRight: '2px' }}></i>
                                      {climaDestino ? (
                                        <>
                                          Mín {Math.round(climaDestino.temperaturaMinima)}°C / Máx {Math.round(climaDestino.temperaturaMaxima)}°C
                                        </>
                                      ) : (
                                        'Cargando...'
                                      )}
                                    </button>
                                  </td>
                                  <td><strong>Salida:</strong> {row.salida_hora}</td>
                                  <td><strong>Llegada:</strong> {row.llegada_hora}</td>
                                  <td><strong>Buque:</strong> {row.buque}</td>
                                  <td><strong>Muelle:</strong> {row.muelle}</td>
                                  <td>
                                    <strong>Estatus:</strong>
                                    <span style={{
                                      textTransform: 'uppercase',
                                      fontWeight: '600',
                                      color: row.estatus === 'En curso' ? '#28A744' : '#007BFF',
                                    }}>
                                      {row.estatus}
                                    </span>
                                  </td>
                                </tr>
                                {mensajeAbierto === row.ruta && (
                        <tr>
                          <td colSpan="7" style={{ padding: '10px', textAlign: 'center' }}>
                            <div className="mensaje">
                              
                              {/* Extraer el destino de la ruta */}
                              {(() => {
                                const destino = row.ruta.split(' - ')[1]; // Obtener el destino
                                const climaDestino = climaCiudad[destino]; // Obtener el clima de la ciudad de destino
                      
                                if (climaDestino) {
                                  return (
                                    <div>
                                      <div className='clima_title'>
                                        <label>Clima</label>
                                        <h4> {destino}</h4>
                                      </div>
                                      <ul className='clima_box'>
                                        {climaDestino.map((dia, index) => (
                                        <li key={index}>
                                          
                                          {/*{dia.fecha}: Mín {dia.temperaturaMinima}°C, Máx {dia.temperaturaMaxima}°C, {dia.descripcion}*/}
                                          <div>{format(parseISO(dia.fecha), "EEEE d", { locale: es })}</div>  
                                          {/*<div>Mín {dia.temperaturaMinima}°C / Máx {dia.temperaturaMaxima}°C </div>*/}
                                          <div> Mín {Math.round(dia.temperaturaMinima)}°C / Máx {Math.round(dia.temperaturaMaxima)}°C</div>
                                          <div> {weatherIcons[dia.descripcion] || <i class="fi fi-rs-question"></i>} {/* Ícono por descripción */}{dia.descripcion}</div>                                      
                                        </li>

                                        ))}
                                      </ul>
                                    </div>
                                  );
                                } else {
                                  return <p>No hay datos de clima disponibles para {destino}.</p>;
                                }
                              })()}
                            </div>
                          </td>
                        </tr>
                                    )}
                              </React.Fragment>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="7" style={{ textAlign: 'center' }}>No hay datos disponibles</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="content-viaje-curso">
            {/* Tabla de los siguientes días */}
            <div className="daily-table">
              <div className="barra1_viaje_curso">
              <label> </label>
                <label>Próximos viajes</label>
              </div>

              {/* Contenedor responsive */}
              <div className="table-responsive">
                <table className="table viaje-curso-tabla prox_viajes">       
                  <tbody>
                    {orderedDays.slice(1).map((day) => (
                      <React.Fragment key={day}>
                        {/* Título del día */}
                        <tr>
                          <td colSpan="6" className='title_table_itinerario' style={{ fontWeight: 'bold', textAlign: 'left' }}>
                            {day}
                          </td>
                        </tr>

                        {/* Datos del día */}
                        {groupedData[day] && groupedData[day].length > 0 ? (
                          groupedData[day].map((row) => (
                            <tr key={`${row.ruta}-${row.salida_hora}`} className="d-flex flex-column"  id='prueba'>
                              <td data-label="Ruta"><strong>Ruta: </strong> {row.ruta}</td>
                              <td data-label="Salida"><strong>Salida: </strong> {row.salida_hora}</td>
                              <td data-label="Llegada"><strong>Llegada: </strong> {row.llegada_hora}</td>
                              <td data-label="Buque"><strong>Buque: </strong> {row.buque}</td>
                              <td data-label="Muelle"><strong>Muelle: </strong> {row.muelle}</td>
                              <td data-label="Estatus">
                                  <strong >Estatus: </strong> <span style={{
                                      textTransform: 'uppercase',
                                      fontWeight: '600',
                                      color: row.estatus === 'En curso' ? '#28A744' : '#007BFF',
                                    }}>
                                    {row.estatus}  
                                    </span>                               
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6" style={{ textAlign: 'center' }}>No hay datos disponibles</td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </section>



      <section className="callToAction">
            <div className='container'>
                <div>
                    <h3> </h3>
                </div>
                <div className='callToAction_row'>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={tarifas} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Consulta nuestras tarifas</h4>
                                </div>
                                <div>
                                    <p>Consulta nuestras tarifas y reserva con anticipación para obtener el mejor precio para viajar.  </p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                                <Link to="/tarifas">Ver tarifas</Link>     
                            </div>
                        </div>
                    </div>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={preguntas} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Preguntas frecuentes</h4>
                                </div>
                                <div>
                                    <p>Aquí encontrarás respuestas sobre nuestros servicios, políticas y más información para un viaje sin contratiempos. </p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                                <Link to="/preguntas-frecuentes">Más información</Link>     
                            </div>
                        </div>
                    </div>
                   {/*<div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Envianos un WhatsApp</h4>
                                </div>
                                <div>
                                    <p>Comunícate con nosotros al instante. Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación nuestro equipo está listo para ayudarte.</p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                            <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                    
                            </div>
                        </div>
                    </div>*/}
                     <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={reservacion} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>¿Listo para reservar?</h4>
                            </div>
                            <div>
                                <p>Inicia tu aventura hoy. Compra tus boletos y emprende un inolvidable viaje por el Mar de Cortés. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">Llamar ahora </a>                        
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </section>

        <Outlet />
        <Footer />


    </>
  );
}
